<!-- @Author: Yu_Bo -->
<template>
  <div>
    <div class='model_allocation zc_dialog_box'>
      <el-dialog :visible.sync="dialogVisible" width="962px" top="10vh" :close-on-click-modal='false'>
        <div class="title" slot="title">
          <div class="name">模特分身分配</div>
        </div>
        <!--  -->
        <div class="all_main" v-loading="loading">
          <div class="main_top">
            <div class="top_txt">
              <span class="name">模特名称：</span>
              <span class="tip">雯静妹妹</span>
            </div>
            <div class="top_txt">
              <span class="name">模特分身：</span>
              <span class="tip">场景一</span>
            </div>
            <div class="top_txt">
              <span class="name">模特有效截止日期：</span>
              <span class="tip">2024-04-17</span>
            </div>
          </div>
          <!--  -->
          <div class="main_input">
            <div class="left">
              <el-input v-model="accoundText" prefix-icon='el-icon-search' size='small'
                placeholder="请输入账号开始搜索"></el-input>
            </div>
            <div class="right">
              <div class="right_title">添加成员：</div>
              <div class="right_add">
                <el-input placeholder="输入关键字进行过滤" size='small' v-model="inputAddTxt">
                  <img slot="suffix" src="@/assets/images/work/model_add.png" alt="">
                </el-input>
                <div class="select-member" v-if="addShow">
                  <div class="me_title">添加成员</div>
                  <div class="me_box">
                    <el-tree :data="data" show-checkbox node-key="id"
                      :default-checked-keys="[5]" :props="defaultProps">
                    </el-tree>
                  </div>
                  <div class="me_bot">
                    <el-button class="btnBgColor_grey" size="small">取消</el-button>
                    <el-button class="btnBgColor_blue" size="small" type="primary">确定</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="main_tabel">
            <el-table :data="list" style="width: 100%"
              header-cell-class-name="table-header" cell-class-name="cell-class">
              <template slot="empty">
                <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
              </template>
              <el-table-column prop="name" label="账号">
              </el-table-column>
              <el-table-column prop="name" label="模特分身">
              </el-table-column>
              <el-table-column label="使用有效截止日期" width="200px">
                <template slot-scope="scope">
                  <div class="scope_time">
                    <div class="time_btn" v-if="scope.row.timeShow">
                      <el-date-picker v-model="time_text" size="small" type="date" placeholder="选择日期">
                      </el-date-picker>
                    </div>
                    <div class="time_icon" v-else @click="timeChange(scope.$index,scope.row)">2023-04-25<i class="el-icon-edit-outline"></i></div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="操作人">
              </el-table-column>
              <el-table-column prop="name" label="操作时间">
              </el-table-column>
              <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="delBtn(scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!--  -->
        <span slot="footer" class="dialog-footer">
          <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
          <el-button class="btnBgColor_blue" size="small" type="primary" @click="affirmBtn">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 温馨提示 -->
    <point-out ref='dialogTip' dialogTitle="提示" type="modelDel" @close='closeListBtn' dialogWidth='380px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
      <div class="zc_dialog_tip_content">
        <div class="text">移除成员后，该成员将不能使用此模特分身，是否移除该成员？</div>
      </div>
    </point-out>
  </div>
</template>

<script>
  import PointOut from '@/components/point_out/point_out.vue'//温馨提示弹框
  import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
  export default {
    components: {
      PointOut,
      EmptyData
    },
    props: {},
    data() {
      return {
        dialogVisible: false,
        accoundText: '',
        inputAddTxt: '',
        addShow:false,
        data: [{
          id: 1,
          label: '一级 1',
          children: [{
            id: 4,
            label: '二级 1-1',
            children: [{
              id: 9,
              label: '三级 1-1-1'
            }, {
              id: 10,
              label: '三级 1-1-2'
            }]
          }]
        }, {
          id: 2,
          label: '一级 2',
          children: [{
            id: 5,
            label: '二级 2-1'
          }, {
            id: 6,
            label: '二级 2-2'
          }]
        }, {
          id: 3,
          label: '一级 3',
          children: [{
            id: 7,
            label: '二级 3-1'
          }, {
            id: 8,
            label: '二级 3-2'
          }]
        }],
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        page: 1,
        limit: 10,
        total: 0,
        loading: false,
        list: [
          {name:'121212',timeShow:false}
        ],
        time_text:'',
        del_index:'',
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      // 打开
      openDialog() {
        this.dialogVisible = true
      },
      // 编辑时间
      timeChange(index,info){
        this.list[index].timeShow=true
      },
      // 删除
      delBtn(index){
        this.$refs.dialogTip.openDialogBtn()
      },
      // 确定删除
      closeListBtn(){},
      // 确定
      affirmBtn() {
        this.dialogVisible = false
      },
      // 取消
      cancelBtn() {
        this.dialogVisible = false
      },

    },
  }
</script>

<style lang='scss' scoped>
  .model_allocation {
    .all_main {
      width: 100%;
      min-height: 100px;

      .main_top {
        width: 100%;
        height: 60px;
        padding: 0 30px;
        background: #F9F9F9;
        border-radius: 2px;
        display: flex;
        align-items: center;

        .top_txt {
          padding-right: 90px;
          display: flex;
          align-items: center;

          .name {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }

          .tip {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
      }

      .main_input {
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          width: 328px;
        }

        .right {
          display: flex;
          align-items: center;

          .right_title {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }

          .right_add {
            position: relative;
            width: 226px;

            ::v-deep .el-input__suffix {
              right: 0;
            }

            .select-member {
              position: absolute;
              top: 33px;
              left: 0;
              z-index: 10;
              width: 100%;
              min-height: 275px;
              padding: 20px;
              background: #fff;
              border-radius: 5px;
              box-shadow: 0px 2px 12px 0px rgba(228, 228, 228, 0.5);

              .me_title {
                width: 100%;
                margin-bottom: 10px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
              }

              .me_box {
                width: 100%;
                min-height: 170px;
              }

              .me_bot {
                width: 100%;
                padding-top: 15px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
              }
            }
          }
        }
      }
      .main_tabel{
        width: 100%;
        margin-top: 20px;
        .el-table{
          width: 100%;
          border-radius: 2px;
          border-top: 1px solid #EBEEF5;
          border-left: 1px solid #EBEEF5;
          border-right: 1px solid #EBEEF5;
        }
        ::v-deep .table-header {
            height: 50px !important;
            background: #F0F2F5 !important;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #666666;
            margin: 0 !important;
            padding: 0 !important;
        }
        .scope_time{
          width: 100%;
          display: flex;
          align-items: center;
          .time_icon{
            cursor: pointer;
            display: flex;
            align-items: center;
            .el-icon-edit-outline{
              font-size: 20px;
              color: #666;
              margin-left: 10px;
            }
          }
          .time_btn{
            width: 160px;
            .el-date-editor{
              width: 100%;
            }
          }
        }
      }
    }
  }
</style>

<!-- @Author: Yu_Bo -->
<template>
    <div class='works_main'>
        <!--  -->
        <div class="main_title">
            <div class="title_left">
                <div class="t_title">
                    <span></span>最近作品
                </div>
                <div class="title_nav">
                    <div class="nav_text" :class="navIndex == index ? 'nav_text_active' : ''" v-for="(item, index) in navList"
                        :key="index" @click="navBtn(index)">
                        <img v-if="navIndex == index" :src="item.selectedIconPath" alt="">
                        <img v-else :src="item.iconPath" alt="">
                        <span>{{ item.name }}</span>
                    </div>
                </div>
            </div>
            <div class="title_right" @click='moreBtn'>
                更多作品<i class="el-icon-arrow-right"></i>
            </div>
        </div>
        <!--  -->
        <div class="main_list">
            <!-- 视频 -->
            <works-video ref="video" v-show="navIndex == 0"></works-video>
            <!-- 音频 -->
            <works-audio ref="auido" v-show="navIndex == 1"></works-audio>
            <!-- 绘画 -->
            <works-draw ref='draw' v-show="navIndex == 2"></works-draw>
            <!-- 漫画 -->
            <works-comic ref='comic' v-show="navIndex == 3"></works-comic>
        </div>
    </div>
</template>

<script>
import WorksVideo from './works_video.vue'
import WorksAudio from './works_audio.vue'
import WorksDraw from './works_draw.vue'
import WorksComic from './works_comic.vue'
export default {
    components: {
        WorksVideo,
        WorksAudio,
        WorksDraw,
        WorksComic
    },
    props: {},
    data() {
        return {
            navIndex: 0,
            navList: [
                { name: '视频', iconPath: require('@/assets/images/work/works_icon_a.png'), selectedIconPath: require('@/assets/images/work/works_icon_aHL.png') },
                { name: '音频', iconPath: require('@/assets/images/work/works_icon_b.png'), selectedIconPath: require('@/assets/images/work/works_icon_bHL.png') },
                { name: 'AI背景', iconPath: require('@/assets/images/work/works_icon_c.png'), selectedIconPath: require('@/assets/images/work/works_icon_cHL.png') },
                { name: 'AI换脸', iconPath: require('@/assets/images/work/works_icon_d.png'), selectedIconPath: require('@/assets/images/work/works_icon_dHL.png') },
            ],
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 切换
        navBtn(index) {
            this.navIndex = index
            // 关闭视频和音频
            this.$refs.video.pauseBtn()
            this.$refs.auido.playAudioBtn()
            // 判断切换
            if (index == 0) {
                this.$refs.video.getList()
            } else if (index == 1) {
                this.$refs.auido.getList()
            } else if (index == 2) {
                this.$refs.draw.getList()
            } else if (index == 3) {
                this.$refs.comic.getList()
            }
        },
        // 更多视频
        moreBtn() {
            if (this.navIndex == 0) {
                // 视频
                this.$router.push('/creation/videoWorks')
            } else if (this.navIndex == 1) {
                // 音频
                this.$router.push('/creation/audioWorks')
            } else if (this.navIndex == 2) {
                // 绘画
                this.$router.push({
                    path: '/creation/drawWorks',
                    query: {
                        type: 1
                    }
                })
            } else if (this.navIndex == 3) {
                // 漫画
                this.$router.push({
                    path: '/creation/drawWorks',
                    query: {
                        type: 2
                    }
                })
            }
        }
    },
}
</script>

<style lang='scss' scoped>
.works_main {
    width: 100%;
    padding: 20px 0 20px 20px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 10px;

    .main_title {
        width: 100%;
        padding-right: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title_left {
            display: flex;
            align-items: center;

            .t_title {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;

                span {
                    width: 5px;
                    height: 18px;
                    margin-right: 10px;
                    background: #2E4BF2;
                    border-radius: 3px;
                }
            }

            .title_nav {
                padding-left: 20px;
                display: flex;
                align-items: center;

                .nav_text {
                    margin-right: 12px;
                    cursor: pointer;
                    height: 29px;
                    padding: 0 10px;
                    background: rgba(255, 255, 255, 0.59);
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        display: block;
                        width: 14px;
                        height: 14px;
                    }

                    span {
                        padding-left: 4px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                    }
                }

                .nav_text_active {
                    background: rgba(66, 94, 255, 0.1);

                    span {
                        color: #2E4BF2;
                    }
                }
            }
        }

        .title_right {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;

            .el-icon-arrow-right {
                margin-left: 4px;
            }
        }
    }

    .main_list {
        width: 100%;
    }
}</style>

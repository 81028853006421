<!-- @Author: Yu_Bo -->
<template>
    <div class='my_template'>
        <!--  -->
        <div class="tem_nav">
            <div class="left_nav">
                <div class="nav" :class="modelIndex == item.type ? 'nav_active' : ''" v-for="(item, index) in modelList"
                    :key="index" @click="modelNavBtn(item.type)">
                    <span class="name">{{ item.name }}</span>
                    <span class="xian"></span>
                </div>
            </div>
            <div class="right_text">
                <div class="t_input">
                    <el-input v-model="inputTxt" size='mini' prefix-icon='el-icon-search'
                        @keyup.enter.native="templateListBtn" @clear='templateListBtn' :clearable="true"
                        placeholder="搜索模板关键字"></el-input>
                </div>
                <div class="t_select">
                    <el-dropdown @command="statusBtn">
                        <span class="el-dropdown-link">
                            {{ statusTxt }}<i class="el-icon-caret-bottom el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(item, index) in statusOptions" :key="index" :command='item.value'>{{
                                item.label }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div class="t_select">
                    <el-dropdown @command='rationBtn'>
                        <span class="el-dropdown-link">
                            {{ ratioTxt }}<i class="el-icon-caret-bottom el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(item, index) in ratioOptions" :key="index" :command='item.value'>{{
                                item.label }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="tem_list_main" v-loading='loading'>
            <div class="tem_list">
                <div class="no_html" v-if="list.length == 0">
                    <img src="@/assets/images/no_html/no_img_b.png" alt="">
                    <div class="txt">这里什么都没有哦~</div>
                    <div class="btn"><span @click="goPayBtn">去购买</span></div>
                </div>
                <template v-else>
                    <div class="list" :class="ratioValue == 1 ? 'list_h' : 'list_s'" v-for="(item, index) in list"
                        :key="index">
                        <div class="list_img">
                            <video v-show="item.show" :ref="'tempVideo' + index" class="video_url" :src="item.video_url"
                                controls="controls" @ended="videoEndedBtn(index)" @pause="videoPauseBtn(index)"
                                controlslist="nodownload noremoteplayback noplaybackrate" disablepictureinpicture></video>
                            <el-image v-show="!item.show" :src="item.video_img" fit="cover"></el-image>
                            <div class="right_top" v-if="item.is_free == 1">限时免费</div>
                            <div class="play_btn" v-show="!item.show" @click="playBtn(index)">
                                <i class="el-icon-caret-right"></i>
                            </div>
                        </div>
                        <div class="list_info">
                            <div class="info_name one-txt-cut">{{ item.name || '--' }}</div>
                            <div class="info_btn">使用</div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="page_box" v-if="total" style="padding-bottom: 20px;padding-right: 20px;">
                <paging-page :total='total' :pageSizes='pageSizes' align='right' @sizeChange='sizeChangeBtn'
                    @currentChange='currentChangeBtn'></paging-page>
            </div>
        </div>
    </div>
</template>

<script>
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
export default {
    components: { PagingPage },
    props: {},
    data() {
        return {
            modelIndex: 1,
            modelList: [
                { name: '最近使用', type: 1, },
                { name: '已购', type: 2, },
                { name: '定制', type: 3, },
                { name: '免费', type: 4, },
            ],
            inputTxt: '',
            statusValue: 0,
            statusOptions: [
                { value: 0, label: '全部' },
            ],
            ratioValue: '',
            ratioOptions: [],
            //
            list: [],
            page: 1,
            limit: 12,
            total: 0,
            pageSizes: [12, 24, 36],
            loading: false,
        }
    },
    computed: {
        statusTxt() {
            var obj = this.statusOptions.find(item => item.value == this.statusValue)
            return obj.label
        },
        ratioTxt() {
            if (this.ratioOptions.length) {
                var obj = this.ratioOptions.find(item => item.value == this.ratioValue)
                return obj.label
            } else {
                return ''
            }
        }
    },
    watch: {},
    created() {
        this.getParams()
    },
    mounted() { },
    methods: {
        // 筛选条件
        getParams() {
            var that = this
            that.$workbenApi.templateParams().then(res => {
                if (res.code == 1000) {
                    //
                    that.ratioOptions = res.result.aspect_ratio
                    that.ratioValue = res.result.aspect_ratio[0].value
                    //
                    that.statusOptions = that.statusOptions.concat(res.result.theme_category)
                    //
                    that.getList()
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 列表
        getList() {
            var that = this
            var parasm = {
                name: that.inputTxt,
                theme_category: this.statusValue == 0 ? '' : that.statusValue,
                aspect_ratio: that.ratioValue
            }
            that.loading = true
            if (that.modelIndex == 1) {
                // 最近使用
                that.$workbenApi.recentUse(parasm).then(res => {
                    that.loading = false
                    if (res.code == 1000) {
                        that.videoShow(res.result)
                        that.total = 0
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            } else if (that.modelIndex == 2) {
                // 已购
                var obj = {
                    ...parasm,
                    type: 1,
                    page: that.page,
                    limit: that.limit
                }
                that.$workbenApi.templateIndex(obj).then(res => {
                    that.loading = false
                    if (res.code == 1000) {
                        that.videoShow(res.result.data)
                        that.total = res.result.total
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            } else if (that.modelIndex == 3) {
                // 定制
                var obj = {
                    ...parasm,
                    type: 2,
                    page: that.page,
                    limit: that.limit
                }
                that.$workbenApi.templateIndex(obj).then(res => {
                    that.loading = false
                    if (res.code == 1000) {
                        that.videoShow(res.result.data)
                        that.total = res.result.total
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            } else if (that.modelIndex == 4) {
                // 免费
                var obj = {
                    ...parasm,
                    page: that.page,
                    limit: that.limit
                }
                that.$workbenApi.freeIndex(obj).then(res => {
                    that.loading = false
                    if (res.code == 1000) {
                        that.videoShow(res.result.data)
                        that.total = res.result.total
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
        // 处理视频show
        videoShow(arr) {
            if (arr && arr.length) {
                arr.forEach(item => {
                    item.show = false
                })
                this.list = arr
            } else {
                this.list = []
            }
        },
        // 播放
        playBtn(index) {
            this.list.forEach((v, i) => {
                if (index == i) {
                    v.show = true
                    let refPlay = eval('this.$refs.tempVideo' + index)[0]
                    refPlay.play()//播放
                } else {
                    let refPause = eval('this.$refs.tempVideo' + i)[0]
                    refPause.pause()//暂停
                    v.show = false
                }
            })
        },
        // 暂停所有
        pauseBtn() {
            this.list.forEach((v, i) => {
                v.show = false
                let ref = eval('this.$refs.tempVideo' + i)[0]
                ref.pause()//暂停
            })
        },
        // 视频播放暂停
        videoPauseBtn(index) {
            this.list[index].show = false
        },
        // 视频播放结束
        videoEndedBtn(index) {
            this.list[index].show = false
        },
        // 切换
        modelNavBtn(index) {
            if (this.modelIndex == index) {
                return
            }
            this.pauseBtn()
            this.modelIndex = index
            this.page = 1
            this.getList()
        },
        // 选择
        statusBtn(val) {
            this.pauseBtn()
            this.statusValue = val
            this.page = 1
            this.getList()
        },
        // 选择比例
        rationBtn(val) {
            this.pauseBtn()
            this.ratioValue = val
            this.page = 1
            this.getList()
        },
        // 改变每页条数
        sizeChangeBtn(val) {
            this.pauseBtn()
            this.limit = val
            this.page = 1
            this.getList()
        },
        // 改变页数
        currentChangeBtn(val) {
            this.pauseBtn()
            this.page = val
            this.getList()
        },
        // 去购买
        goPayBtn() {
            this.pauseBtn()
            this.$router.push({
                path: '/digital/index',
                query: {
                    type: 3
                }
            })
        },
        //
        templateListBtn() {
            this.page = 1
            this.getList()
        },
    },
}
</script>

<style lang='scss' scoped>
.my_template {
    width: 100%;
    margin-top: 25px;

    .tem_nav {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left_nav {
            display: flex;

            .nav {
                cursor: pointer;
                position: relative;
                width: 104px;
                height: 50px;
                background: rgba(255, 255, 255, 0.7);

                .name {
                    display: block;
                    width: 100%;
                    line-height: 50px;
                    text-align: center;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
            }

            .nav_active {
                background: rgba(255, 255, 255, 0.47);

                .name {
                    color: #2E4BF2;
                }

                .xian {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    display: block;
                    width: 25px;
                    height: 2px;
                    margin-left: -13px;
                    background: #2E4BF2;
                }
            }

            .nav:first-child {
                border-radius: 10px 0px 0px 0px;
            }

            .nav:last-child {
                border-radius: 0px 10px 0px 0px;
            }
        }

        .right_text {
            display: flex;
            align-items: center;

            .t_input {
                width: 186px;
            }

            .t_select {
                cursor: pointer;
                margin-left: 30px;

                .el-dropdown-link {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
            }
        }
    }

    .tem_list_main {
        width: 100%;
        padding-top: 30px;
        border-radius: 0 10px 10px;
        background: rgba(255, 255, 255, 0.47);
    }

    .tem_list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .list {
            cursor: pointer;
            margin-left: 30px;
            margin-bottom: 30px;
            background: #fff;
            border-radius: 10px;
            overflow: hidden;

            .list_img {
                position: relative;
                width: 100%;

                .el-image {
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                }

                .right_top {
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 3;
                    padding: 0 10px;
                    height: 20px;
                    line-height: 20px;
                    background: rgba(0, 0, 0, 0.5);
                    border-radius: 0px 10px 0px 10px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }

                .play_btn {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 4;
                    width: 40px;
                    height: 40px;
                    margin-top: -20px;
                    margin-left: -20px;
                    background: rgba(255, 255, 255, 0.3);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .el-icon-caret-right {
                        font-size: 30px;
                        color: #fff;
                    }
                }

                .video_url {
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 1;
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }

            .list_info {
                width: 100%;
                padding: 0 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .info_name {
                    width: 130px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }

                .info_btn {
                    width: 56px;
                    height: 26px;
                    line-height: 26px;
                    text-align: center;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    background: #2E4BF2;
                    border-radius: 2px;
                }
            }
        }

        .list_s {
            width: 214px;
            height: 422px;

            .list_img {
                height: 382px;
            }

            .list_info {
                height: 40px;
            }
        }

        .list_h {
            width: 350px;
            height: 240px;

            .list_img {
                height: 190px;
            }

            .list_info {
                height: 50px;
            }
        }
    }
}</style>

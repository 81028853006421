<!-- @Author: Yu_Bo -->
<template>
	<div class='workben_main'>
    <!-- 数字孪生-声音克隆-AI绘画-AI漫画-开发者平台 -->
    <workben-nav></workben-nav>
    <!-- 最近作品 -->
    <workben-works></workben-works>
    <!-- 我的数字资产 -->
    <workben-property></workben-property>
	</div>
</template>

<script>
  import WorkbenNav from './components/workben_nav/workben_nav.vue'
  import WorkbenWorks from './components/workben_works/workben_works.vue'
  import WorkbenProperty from './components/workben_property/workben_property.vue'
	export default {
		components: {
      WorkbenNav,
      WorkbenWorks,
      WorkbenProperty,
    },
		props: {},
		data() {
			return{

			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {},
	}
</script>

<style lang='scss' scoped>
	.workben_main{
    width: 100%;
    min-width: 1400px;
	}
</style>

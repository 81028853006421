<!-- @Author: Yu_Bo -->
<template>
	<div class='my_model'>
    <!--  -->
    <div class="model_nav">
      <div class="nav" :class="modelIndex==item.type?'nav_active':''" v-for="(item,index) in modelList" :key="index" @click="modelNavBtn(item.type)">
        <span class="name">{{item.name}}</span>
        <span class="xian"></span>
      </div>
    </div>
    <!--  -->
    <div class="model_list" v-loading='listLoading'>
      <div :class="retractShow?'scroll_bar_x list_box':'scroll_bar_x list'">
        <div class="no_html" v-if="list.length==0">
          <img src="@/assets/images/no_html/no_img_b.png" alt="">
          <div class="txt">您最近还没有使用过模特，请在“模特市场”中挑选使用</div>
          <div class="btn"><span @click="modellBtn">去购买</span></div>
        </div>
        <div class="list_text" :class="listIndex==index?'list_text_active':''" v-for="(item,index) in list" :key="index" @click="listBtn(index)">
          <template v-if="modelIndex==1">
            <div class="right_tip color1" v-if="item.type_mark=='free'">免费</div>
            <div class="right_tip color2" v-if="item.type_mark=='vip'">VIP</div>
            <div class="right_tip color3" v-if="item.type_mark=='customize'">定制</div>
          </template>
          <template v-if="modelIndex==2">
            <div class="right_tip color2">VIP</div>
          </template>
          <template v-if="modelIndex==3">
            <div class="right_tip color6" v-if="item.status==1">机审通过</div>
            <div class="right_tip color4" v-if="item.status==2">机审未通过</div>
            <div class="right_tip color7" v-if="item.status==3">训练中</div>
            <div class="right_tip color6" v-if="item.status==4">训练成功</div>
            <div class="right_tip color5" v-if="item.status==5">训练失败</div>
            <div class="right_tip color3" v-if="item.status==6">定制</div>
          </template>
          <template v-if="modelIndex==4">
            <div class="right_tip color1">免费</div>
          </template>
          <div class="text_info">
            <div class="info_img">
              <el-image v-if="item.thumbnail" style="width: 100%; height: 100%" :src="item.thumbnail" fit="cover"></el-image>
              <el-avatar v-else style="width: 100%; height: 100%;line-height: 60px;font-size: 28px;" icon="el-icon-s-custom"></el-avatar>
            </div>
            <div class="info_txt">
              <div class="name one-txt-cut">{{item.name}}</div>
              <div class="time" v-if="modelIndex==1 || modelIndex==3">{{item.expiry_date?item.expiry_date+'到期':'账号使用内有效'}}</div>
              <div class="time" v-if="modelIndex==2 || modelIndex==4">{{item.expire_day}}天内有效</div>
              <div class="btn"><div @click.stop="detailsBtn(item)">查看详情<i class="el-icon-arrow-right"></i></div></div>
            </div>
          </div>
        </div>
      </div>
      <div class="list_btn" v-if="list.length>=10" @click="retractShow=!retractShow">
        {{retractShow?'展开':'收起'}}<i :class="retractShow?'el-icon-arrow-down':'el-icon-arrow-up'"></i>
      </div>
    </div>
    <!-- 分身 -->
    <div class="model_avatar">
      <div class="ava_title">
        <div class="title">模特分身</div>
        <div class="t_text">
          <div class="txt">分身类型</div>
          <div class="t_select">
            <el-select v-model="value" size="mini" placeholder="请选择" @change="separationBtn" :clearable="true">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="t_input">
            <el-input v-model="inputTxt" size='mini' @keyup.enter.native="separationBtn" @clear='separationBtn' :clearable="true" placeholder="搜索场景名称"></el-input>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="ava_list" v-loading='loading'>
        <empty-data v-if="total==0" img='no_img_b.png' text='暂无数据'></empty-data>
        <div class="list" v-for="(item,index) in avaList" :key="index">
          <div class="list_img">
            <el-image style="width: 100%; height: 100%" :src="item.thumbnail" fit="scale-down"></el-image>
            <div class="left_top">通用</div>
            <div class="right_top" @click.stop="detailsFSbtn(item)">详情<i class="el-icon-arrow-right"></i></div>
            <div class="play_btn" @click.stop="videoBtn(item.demo_video)">
              <i class="el-icon-caret-right"></i>
            </div>
          </div>
          <div class="list_info">
            <div class="info_left">
              <div class="left_name">{{item.scene_name}}</div>
              <div class="left_time">时长：{{item.video_time}}</div>
            </div>
            <div class="info_right">
              <div class="right_btn">做视频</div>
              <el-dropdown @command='dianBtn'>
                <span class="el-dropdown-link">
                  <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command='1'>模特分身分配</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="page_box" v-if="total" style="padding-top: 20px;">
      	<paging-page :total='total' :pageSizes='pageSizes' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
      </div>
    </div>
    <!-- 分身详情 -->
    <details-fenshen ref="fsDetails" :options='options'></details-fenshen>
    <!-- 详情 -->
    <details-model ref="details"></details-model>
    <!-- 视频 -->
    <video-box ref="videoRefs"></video-box>
    <!-- 模特分身分配 -->
    <model-allocation ref="allocation"></model-allocation>
	</div>
</template>

<script>
  import { mapState } from "vuex"
  import DetailsFenshen from '@/components/details_popover/details_fenshen.vue'//分身详情
  import DetailsModel from '@/components/details_popover/details_model.vue'//详情
  import VideoBox from '@/components/details_popover/video_box.vue'//详情
  import ModelAllocation from '@/components/details_popover/model_allocation.vue'//模特分身分配
  import PagingPage from '@/components/paging_page/paging_page.vue'//分页
  import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
	export default {
		components: {
      DetailsFenshen,
      DetailsModel,
      VideoBox,
      ModelAllocation,
      PagingPage,
      EmptyData
    },
		props: {
      txt:{
        type:String,
        defaul:'',
      }
    },
		data() {
			return{
        modelIndex:1,
        //
        listIndex:0,
        list:[],
        listLoading:false,
        // 收起展开
        retractShow:true,
        // 分身
        value:'',
        options:[],
        inputTxt:'',
        avaList:[],
        page:1,
        limit:12,
        total: 0,
        pageSizes:[12,24,36],
        loading: false,
			}
		},
		computed: {
      ...mapState({
          userInfo: state => state.userInfo.user_info, // 用户信息
      }),
      modelList(){
        if(this.userInfo.is_vip==1){
          return [
            {name:'最近使用',type:1},
            {name:'VIP',type:2},
            {name:'定制模特',type:3},
            {name:'免费模特',type:4},
          ]
        }else{
          return[
            {name:'最近使用',type:1},
            {name:'定制模特',type:3},
            {name:'免费模特',type:4},
          ]
        }
      },
    },
		watch: {},
		created() {
      this.getParams()
      this.getList()
    },
		mounted() {},
		methods: {
      // 获取分身筛选下拉
      getParams(){
        var that = this
        that.$workbenApi.listScenceParams().then(res => {
          if (res.code == 1000) {
            that.options=res.result.model_type
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 最近使用模特列表
      getList(){
        var that = this
        that.listLoading=true
        var params = {
          name:that.txt,
          page:1,
          limit:20,
        }
        if(that.modelIndex==1){
          // 最近使用
          that.$workbenApi.modelRecently(params).then(res => {
            that.listLoading=false
            if (res.code == 1000) {
              that.list=res.result || []
              if(that.list.length){
                that.separationBtn()
              }
            } else {
              that.$errMsg(res.message)
            }
          })
        }else if(that.modelIndex==2){
          // vip
          var obj = {
            ...params,
            type:2,
          }
          that.$workbenApi.marketModelList(obj).then(res => {
            that.listLoading=false
            if (res.code == 1000) {
              that.list=res.result.data
              if(that.list.length){
                that.separationBtn()
              }
            } else {
              that.$errMsg(res.message)
            }
          })
        }else if(that.modelIndex==3){
          // 定制模特
          that.$workbenApi.getMyModel(params).then(res => {
            that.listLoading=false
            if (res.code == 1000) {
              that.list=res.result.data
              if(that.list.length){
                that.separationBtn()
              }
            } else {
              that.$errMsg(res.message)
            }
          })
        }else if(that.modelIndex==4){
          // 免费模特
          var obj = {
            ...params,
            type:1,
          }
          that.$workbenApi.marketModelList(obj).then(res => {
            that.listLoading=false
            if (res.code == 1000) {
              that.list=res.result.data
              if(that.list.length){
                that.separationBtn()
              }
            } else {
              that.$errMsg(res.message)
            }
          })
        }
      },
      // 切换
      modelNavBtn(index){
        this.modelIndex=index
        this.modelListBtn()
      },
      modelListBtn(){
        this.listIndex=0
        this.retractShow=true
        this.page=1
        this.getList()
      },
      // 切换分身
      listBtn(index){
        var status = this.list[index].status
        if(this.modelIndex==3 && (status==2 || status==5)){
          // 计审未通过/训练失败
          this.$router.push('/workben/digital_edit/'+this.list[index].id)
        }else if(this.modelIndex==3 && (status== 1 || status== 3 || status== 4)){
          return
        }else{
          this.page=1
          this.total=0
          this.avaList=[]
          this.listIndex=index
          this.separationBtn()
        }
      },
      // 分身列表
      separationBtn(){
        var that = this
        that.loading=true
        var params = {
          type:that.modelIndex==1?that.list[that.listIndex].type:that.modelIndex,
          model_id:that.list[that.listIndex].id,
          sence_name:that.inputTxt,
          model_type:that.value,
          page:that.page,
          limit:that.limit,
        }
        that.$workbenApi.modelListScence(params).then(res => {
          that.loading=false
          if (res.code == 1000) {
            that.avaList=res.result.data
            that.total=res.result.total
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 改变每页条数
      sizeChangeBtn(val){
        this.limit = val
        this.page = 1
        this.separationBtn()
      },
      // 改变页数
      currentChangeBtn(val){
        this.page = val
        this.separationBtn()
      },
      // 查看详情
      detailsBtn(info){
        if(this.modelIndex==3 && info.status != 6){
          return
        }
        this.$refs.details.openDialog(info,this.modelIndex)
      },
      // 分身详情
      detailsFSbtn(info){
        this.$refs.fsDetails.openDialog(info)
      },
      // 视频
      videoBtn(url){
        this.$refs.videoRefs.openDialog(url)
      },
      // 模特分身分配
      dianBtn(val){
        if(val==1){
          this.$refs.allocation.openDialog()
        }
      },
      // 去购买
      modellBtn(){
        this.$router.push('/digital/index')
      },
    },
	}
</script>

<style lang='scss' scoped>
	.my_model{
    width: 100%;
    margin-top: 25px;
    .model_nav{
      width: 100%;
      display: flex;
      .nav{
        cursor: pointer;
        position: relative;
        width: 104px;
        height: 50px;
        background: rgba(255,255,255,0.7);
        .name{
          display: block;
          width: 100%;
          line-height: 50px;
          text-align: center;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
      .nav_active{
        background: rgba(255,255,255,0.47);
        .name{
          color: #2E4BF2;
        }
        .xian{
          position: absolute;
          bottom: 0;
          left: 50%;
          display: block;
          width: 25px;
          height: 2px;
          margin-left: -13px;
          background: #2E4BF2;
        }
      }
      .nav:first-child{
        border-radius: 10px 0px 0px 0px;
      }
      .nav:last-child{
        border-radius: 0px 10px 0px 0px;
      }
    }
    .model_list{
      width: 100%;
      min-height: 170px;
      padding-top: 20px;
      border-radius: 0 10px 10px;
      background: rgba(255,255,255,0.47);
      .list{
        width: 100%;
        height: 280px;
        padding-left: 20px;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        .list_text{
          width: 260px;
        }
      }
      .list_box{
        width: 100%;
        padding-left: 20px;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        .list_text{
          min-width: 260px;
        }
      }
      .list_text{
        cursor: pointer;
        border: 1px solid rgba(255,255,255,0);
        position: relative;
        height: 130px;
        margin-bottom: 20px;
        margin-right: 20px;
        border-radius: 10px;
        overflow: hidden;
        background: url(~@/assets/images/work/model_bg.png) no-repeat;
        background-size: 100% 100%;
        .right_tip{
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;
          padding: 0 10px;
          height: 20px;
          line-height: 20px;
          border-radius: 0px 10px 0px 10px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }
        .color1{
          color: #2E3EF2;
          background: rgba(46, 113, 242, 0.14);
        }
        .color2{
          color: #F28A2E;
          background: rgba(242, 136, 46, 0.14);
        }
        .color3{
          color: #774501;
          background: rgba(255, 192, 77, 1);
        }
        .color4{
          color: #7D2EF2;
          background: rgba(156, 46, 242, 0.14);
        }
        .color5{
          color: #fff;
          background: rgba(255, 77, 77, 1);
        }
        .color6{
          color: #59CB58;
          background: rgba(69, 242, 46, 0.14);
        }
        .color7{
          color: #999;
          background: rgba(0, 0, 0, 0.14);
        }
        .text_info{
          width: 100%;
          height: 100%;
          padding: 24px 15px 15px 20px;
          display: flex;
          justify-content: space-between;
          .info_img{
            width: 64px;
            height: 64px;
            border-radius: 50%;
            overflow: hidden;
          }
          .info_txt{
            width: 145px;
            height: 100%;
            .name{
              font-size: 16px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
            }
            .time{
              padding-top: 6px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
            .btn{
              padding-top: 24px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #2E4BF2;
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }
          }
        }
      }
      .list_text_active{
        border: 1px solid #2E3EF2;
      }
      .list_btn{
        cursor:pointer;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        .el-icon-arrow-up{
          margin-left: 5px;
        }
      }
    }
    /*  */
    .model_avatar{
      width: 100%;
      margin-top: 20px;
      padding: 20px;
      border-radius: 10px;
      background: rgba(255,255,255,0.47);
      .ava_title{
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(255,255,255,0.9);
        display: flex;
        align-items: center;
        .title{
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .t_text{
          padding-left: 55px;
          display: flex;
          align-items: center;
          .txt{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
          .t_select{
            margin-left: 10px;
            width: 120px;
          }
          .t_input{
            margin-left: 10px;
            width: 186px;
          }
        }
      }
      /*  */
      .ava_list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .list{
          cursor: pointer;
          width: 249px;
          height: 296px;
          padding: 8px;
          margin-top: 15px;
          margin-right: 15px;
          border-radius: 2px;
          border: 1px solid rgba(255,255,255,0);
          .list_img{
            position: relative;
            width: 100%;
            height: 233px;
            border-radius: 2px;
            background: #E9E8EA;
            .left_top{
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              padding: 0 10px;
              height: 20px;
              line-height: 20px;
              background: #2E4BF2;
              border-radius: 2px 1px 1px 1px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
            }
            .right_top{
              position: absolute;
              top: 0;
              right: 0;
              z-index: 1;
              padding: 0 5px 0 10px;
              height: 20px;
              line-height: 20px;
              background: rgba(122, 122, 122, 0.6);
              border-radius: 2px 1px 1px 1px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
            }
            .play_btn{
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: 2;
              width: 40px;
              height: 40px;
              margin-top: -20px;
              margin-left: -20px;
              background: rgba(0,0,0,0.3);
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              .el-icon-caret-right{
                font-size: 30px;
                color: #fff;
              }
            }
          }
          .list_info{
            height: 40px;
            margin-top: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .info_left{
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              .left_name{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
              }
              .left_time{
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              }
            }
            .info_right{
              display: flex;
              align-items: center;
              .right_btn{
                width: 58px;
                height: 28px;
                line-height: 28px;
                text-align: center;
                background: linear-gradient(90deg, #4875FF 0%, #2B4AFE 100%);
                border-radius: 4px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
              }
              .el-icon-more {
                margin-left: 8px;
                font-size: 18px;
                color: #333333;
                transform: rotate(90deg);
              }
            }
          }
        }
        /* .list:first-child{
          border: 1px solid #2E4BF2;
        } */
        .list:hover{
          border: 1px solid #2E4BF2;
        }
      }
    }
	}
</style>
